import React, { useState } from "react";
import axios from "axios";
import { setAuthToken } from "../utils/auth";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import NavBarHome from "../components/NavBarHome";
import Footer from "../components/Footer";
import hero from "../assets/hero-image.png";
import ToastMsg from "../components/ToastMsg";

const RegisterPage = () => {
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    fname: "",
    lname: "",
    mnumber: "",
    email: "",
    password: "",
    password2: "",
  });
  const { fname, lname, mnumber, email, password, password2 } = formData;
  const [showToast, setShowToast] = useState(false);

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async (e) => {
    e.preventDefault();
    if (password !== password2) {
      setError("Passwords do not match.");
      setShowToast(true);
    } else {
      const newUser = { fname, lname, mnumber, email, password };
      try {
        const res = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/users/register`,
          newUser
        );
        setAuthToken(res.data.token);
        navigate("/login");
        alert("User Registered, Login into user account.");
      } catch (err) {
        setError("Register Failed, Please try again later.");
        setShowToast(true);
        console.error(err);
      }
    }
  };

  const handleCloseToast = () => {
    setShowToast(false);
  };


  return (
    <div className=" h-screen md:h-full">
    <div
      className=" bg-cover bg-center h-full  animate-fade-in"
      style={{ backgroundImage: `url(${hero})` }}
    >
      <NavBarHome />
      <div className="h-full flex flex-col items-center lg:flex-row animate-fade-in-up md:px-40 text-[#5B5643] ">


        <div className="h-full w-full flex justify-start items-center px-4 sm:px-10 lg:px-0 animate-fade-in-up ">
          <div className="bg-white w-full my-40 max-w-lg md:max-w-3/5 lg:w-3/5 rounded-3xl flex flex-col items-center p-4 sm:p-6 md:p-8 animate-fade-in flex-grow">
            <h1 className=" font-bold text-xl sm:text-2xl md:text-3xl sm:mt-4 md:mt-8 w-full text-center animate-fade-in-up">
              New Register
            </h1>
            <h1 className=" font-normal  text-xs sm:text-sm md:text-lg mt-2 sm:mt-4 md:mt-6 text-center px-2 md:px-6 animate-fade-in-up">
              Seamless Access, Elevating Experience. Unlock Your Property
              Journey with RAK Groups
            </h1>
            {error && (
              <p className="text-red-500 text-center animate-fade-in-up">
                {error}
              </p>
            )}
            <div className="w-full animate-fade-in ">
              <form
                onSubmit={onSubmit}
                className="w-full flex flex-col sm:mt-6 animate-fade-in-up"
              >
                {/* Input fields */}
                <input
                  type="text"
                  name="fname"
                  value={fname}
                  onChange={onChange}
                  placeholder="First Name"
                  required
                  className="w-full p-2 border rounded-xl border-zinc-500 autofill:bg-transparent mt-4 focus:outline-none focus:ring focus:ring-gray-400 transition duration-300 animate-fade-in-up"
                />
                <input
                  type="text"
                  name="lname"
                  value={lname}
                  onChange={onChange}
                  placeholder="Last Name"
                  required
                  className="w-full p-2 border rounded-xl border-zinc-500 autofill:bg-transparent mt-4 focus:outline-none focus:ring focus:ring-gray-400 transition duration-300 animate-fade-in-up"
                />
                <input
                  type="text"
                  name="mnumber"
                  value={mnumber}
                  onChange={onChange}
                  placeholder="Mobile Number"
                  required
                  className="w-full p-2 border rounded-xl border-zinc-500 autofill:bg-transparent mt-4 focus:outline-none focus:ring focus:ring-gray-400 transition duration-300 animate-fade-in-up"
                />
                <input
                  type="email"
                  name="email"
                  value={email}
                  onChange={onChange}
                  placeholder="Email"
                  required
                  className="w-full p-2 border rounded-xl border-zinc-500 autofill:bg-transparent mt-4 focus:outline-none focus:ring focus:ring-gray-400 transition duration-300 animate-fade-in-up"
                />
                <input
                  type="password"
                  name="password"
                  value={password}
                  minlength="8"
                  onChange={onChange}
                  placeholder="Password"
                  required
                  className="w-full p-2 border rounded-xl  border-zinc-500 autofill:bg-transparent mt-4 focus:outline-none focus:ring focus:ring-gray-400 transition duration-300 animate-fade-in-up"
                />
                <input
                  type="password"
                  name="password2"
                  value={password2}
                  minlength="8"
                  onChange={onChange}
                  placeholder="Confirm Password"
                  required
                  className="w-full p-2 border rounded-xl border-zinc-500 autofill:bg-transparent mt-4 focus:outline-none focus:ring focus:ring-gray-400 transition duration-300 animate-fade-in-up"
                />
                <button
                  type="submit"
                  className="w-full bg-[#CCAC00] hover:bg-[#CCAC00] hover:text-white font-bold py-2 px-4 rounded-xl mt-6 focus:outline-none focus:ring focus:ring-yellow-400 transition duration-300 ease-in-out transform hover:scale-105 animate-fade-in-up"
                >
                  Register
                </button>
              </form>
              <div className="mt-2 text-xs sm:text-sm md:text-lg flex justify-center items-center flex-col animate-fade-in-up">
                <p className="">Already have an account?</p>
                <Link to="/login" className="text-[#CCAC00] hover:underline">
                  Login here
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
    <Footer />
      {/* Toast Message */}
      {showToast && (
        <ToastMsg
          message={error}
          onClose={handleCloseToast}
          className="fixed bottom-4 right-4 animate-fade-in"
        />
      )}
    </div>

  );
};

export default RegisterPage;
