import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HomePage from "./pages/HomePage";
import AboutPage from "./pages/AboutPage";
import ProjectsPage from "./pages/ProjectsPage";
import TeamPage from "./pages/TeamPage";
import EnquirePage from "./pages/EnquirePage";
import LoginPage from "./pages/LoginPage";
import RegisterPage from "./pages/RegisterPage";
import PropertiesPage from "./pages/PropertiesPage";
import BookingPage from "./pages/BookingPage";
import AgentDashboard from "./pages/AgentDashboard";
import OwnerDashboard from "./pages/OwnerDashboard";
import OwnerDashboardAgent from "./pages/OwnerDashboardAgent";
import OwnerDashboardUser from "./pages/OwnerDashboardUser";
import UserDashboard from "./pages/UserDashboard";
import UserSettingsPage from "./pages/UserSettingsPage";
import UserDashboardFiles from "./pages/UserDashboardFiles";
import AgentSettingsPage from "./pages/AgentSettingsPage";
import OwnerSettingsPage from "./pages/OwnerSettingsPage";
import PlotsPage from "./pages/PlotsPage";
import PlotDetailsPage from "./pages/PlotDetailsPage";
import PlotDetailsPageP1 from "./pages/PlotDetailsPageP1";
import VillaDetailsPage from "./pages/VillaDetailsPage";
import VillasPage from "./pages/VillasPage";
import PrivateRoute from "./components/PrivateRoute";
import { AuthProvider } from "./context/AuthContext";
import SplashScreen from "./components/SplashScreen";
import VRExperience from "./components/VRExperience";
import VRExperience2 from "./components/VRExperience2";
import VRExperience3 from "./components/VRExperience3";
import VRExperience4 from "./components/VRExperience4";
import VRExperience5 from "./components/VRExperience5";
import VRExperience6 from "./components/VRExperience6";
import VRExperience7 from "./components/VRExperience7";
import VRExperience8 from "./components/VRExperience8";
import VRExperience9 from "./components/VRExperience9";
import Tandc from "./pages/tandc";
import Error404 from "./pages/Error404";
import Test from "./pages/test";

function App() {
  return (
    <Router>
      <AuthProvider>
        <Routes>
          <Route path="/home" exact element={<HomePage />} />
          <Route path="/" element={<SplashScreen />} />
          <Route path="/vr-experience" element={<VRExperience />} />
          <Route path="/vr-experience2" element={<VRExperience2 />} />
          <Route path="/vr-experience3" element={<VRExperience3 />} />
          <Route path="/vr-experience4" element={<VRExperience4 />} />
          <Route path="/vr-experience5" element={<VRExperience5 />} />
          <Route path="/vr-experience6" element={<VRExperience6 />} />
          <Route path="/vr-experience7" element={<VRExperience7 />} />
          <Route path="/vr-experience8" element={<VRExperience8 />} />
          <Route path="/vr-experience9" element={<VRExperience9 />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/projects" element={<ProjectsPage />} />
          <Route path="/team" element={<TeamPage />} />
          <Route path="/enquire" element={<EnquirePage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/register" element={<RegisterPage />} />
          <Route path="/properties" element={<PropertiesPage />} />
          <Route path="/booking" element={<BookingPage />} />
          <Route path="/villaspage" element={<VillasPage />} />
          <Route path="/plotspage" element={<PlotsPage />} />
          <Route path="/plotdetailspage" element={<PlotDetailsPage />} />
          <Route
            path="/plotdetailspage-phase-1"
            element={<PlotDetailsPageP1 />}
          />
          <Route path="/villadetailspage" element={<VillaDetailsPage />} />
          <Route
            path="/user-dashboard"
            element={
              <PrivateRoute>
                <UserDashboard />
              </PrivateRoute>
            }
          />
          <Route
            path="/user-dashboard-files"
            element={
              <PrivateRoute>
                <UserDashboardFiles />
              </PrivateRoute>
            }
          />
          <Route
            path="/agent-dashboard"
            element={
              <PrivateRoute>
                <AgentDashboard />
              </PrivateRoute>
            }
          />
          <Route
            path="/owner-dashboard"
            element={
              <PrivateRoute>
                <OwnerDashboard />
              </PrivateRoute>
            }
          />
          <Route
            path="/owner-dashboard-agent"
            element={
              <PrivateRoute>
                <OwnerDashboardAgent />
              </PrivateRoute>
            }
          />
          <Route
            path="/owner-dashboard-user"
            element={
              <PrivateRoute>
                <OwnerDashboardUser />
              </PrivateRoute>
            }
          />
          <Route
            path="/user-settings"
            element={
              <PrivateRoute>
                <UserSettingsPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/agent-settings"
            element={
              <PrivateRoute>
                <AgentSettingsPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/owner-settings"
            element={
              <PrivateRoute>
                <OwnerSettingsPage />
              </PrivateRoute>
            }
          />

          <Route path="/tandc" element={<Tandc />} />
          <Route path="/test" element={<Test />} />
          <Route path="/error" element={<Error404 />} />
        </Routes>
      </AuthProvider>
    </Router>
  );
}

export default App;
