// tandc.jsx
import React from "react";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";

const tandc = () => {
  return (
    <div className="min-h-screen bg-gray-50">
      <NavBar />
      <div className="p-6 sm:p-8 md:p-20 flex flex-col space-y-6">
        <h2 className="text-3xl font-bold text-center mb-8 text-slate-500">
          Terms and Conditions & Privacy Policy
        </h2>

        <section>
          <h1 className="text-2xl font-semibold mb-4 text-slate-500">1. Introduction</h1>
          <p className="text-lg text-slate-500">
            This page outlines the Terms and Conditions and Privacy Policy
            applicable to our website and services. By accessing our services,
            you agree to comply with these terms. If you do not agree, please
            discontinue use immediately.
          </p>
        </section>

        <hr className="my-4 border-t-2 border-gray-300" />

        <section>
          <h1 className="text-2xl font-semibold mb-4 text-slate-500">2. Terms and Conditions</h1>
          <p className="text-lg text-slate-500">
            These Terms establish a legal agreement between you and us, governing
            your use of our website and services.
          </p>
          <h2 className="text-xl font-semibold mt-4 text-slate-500">Key Terms:</h2>
          <ol className="list-decimal pl-6 space-y-2 text-lg text-slate-500">
            <li>
              <b>Use of Services:</b> You are responsible for maintaining the
              confidentiality of your account credentials and ensuring all
              provided information is accurate and current.
            </li>
            <li>
              <b>Proprietary Rights:</b> All content, trademarks, and intellectual
              property on this platform are owned by us and may not be used
              without permission.
            </li>
            <li>
              <b>Service Modification:</b> We reserve the right to amend the terms
              or modify services at any time. Continued use signifies acceptance
              of changes.
            </li>
            <li>
              <b>Termination of Services:</b> We may suspend or terminate your
              account for misuse or non-compliance with the terms.
            </li>
            <li>
              <b>Limitation of Liability:</b> We are not liable for indirect,
              incidental, or consequential damages related to the use of our
              services.
            </li>
          </ol>
          <p className="mt-4 text-slate-500">
            For a detailed version, please refer to the original Razorpay terms 
            <a
              href="https://razorpay.com/terms/"
              className="text-blue-600 underline ml-1"
              target="_blank"
              rel="noopener noreferrer"
            >
              here
            </a>.
          </p>
        </section>

        <hr className="my-4 border-t-2 border-gray-300" />

        <section>
          <h1 className="text-2xl font-semibold mb-4 text-slate-500">3. Privacy Policy</h1>
          <p className="text-lg text-slate-500">
            Your privacy is important to us. This Privacy Policy describes how we
            collect, use, and protect your personal information.
          </p>
          <h2 className="text-xl font-semibold mt-4 text-slate-500">Information We Collect:</h2>
          <ul className="list-disc pl-6 space-y-2 text-lg text-slate-500">
            <li><b>Personal Data:</b> Name, contact details, and payment information.</li>
            <li>
              <b>Activity Data:</b> Information about your interactions with the
              website, such as IP addresses and browser type.
            </li>
            <li>
              <b>Cookies:</b> We use cookies to enhance your experience and track
              usage data. You can disable cookies through your browser settings.
            </li>
          </ul>

          <h2 className="text-xl font-semibold mt-4 text-slate-500">How We Use Your Information:</h2>
          <ul className="list-disc pl-6 space-y-2 text-lg text-slate-500">
            <li>To provide services and process transactions.</li>
            <li>To send updates, marketing communications, and respond to inquiries.</li>
            <li>For fraud prevention and compliance with legal obligations.</li>
            <li>To improve user experience through analytics and customer feedback.</li>
          </ul>

          <h2 className="text-xl font-semibold mt-4 text-slate-500">Data Sharing and Security:</h2>
          <ul className="list-disc pl-6 space-y-2 text-lg text-slate-500">
            <li>
              We may share your information with third-party service providers
              only to facilitate transactions or comply with legal requirements.
            </li>
            <li>
              We implement industry-standard security measures to protect your
              data. However, no online system can guarantee absolute security.
            </li>
          </ul>
          <p className="mt-4 text-slate-500">
            For detailed privacy practices, visit the full policy 
            <a
              href="https://razorpay.com/privacy/"
              className="text-blue-600 underline ml-1"
              target="_blank"
              rel="noopener noreferrer"
            >
              here
            </a>.
          </p>
        </section>

        <hr className="my-4 border-t-2 border-gray-300" />

        <section>
          <h1 className="text-2xl font-semibold mb-4 text-slate-500">4. Grievance Redressal</h1>
          <p className="text-lg text-slate-500">
            If you have any questions or concerns regarding this policy, you may
            contact the Grievance Officer:
          </p>
          <ul className="list-none pl-0 space-y-2 text-lg text-slate-500">
            <li><b>Name:</b> Mr. Shashank Karincheti</li>
            <li><b>Email:</b> <a href="mailto:dpo@razorpay.com" className="text-blue-600 underline">dpo@razorpay.com</a></li>
            <li><b>Phone:</b> +91 80-46669555</li>
            <li><b>Address:</b> No. 22, 1st Floor, SJR Cyber, Hosur Road, Bangalore - 560030</li>
          </ul>
        </section>

        <hr className="my-4 border-t-2 border-gray-300" />

        <section>
          <h1 className="text-2xl font-semibold mb-4 text-slate-500">5. Updates to the Policy</h1>
          <p className="text-lg text-slate-500">
            We may revise these terms or the privacy policy periodically. Changes
            take effect immediately upon publication. Continued use after changes
            indicates your acceptance of the revised terms.
          </p>
        </section>
      </div>
      <Footer />
    </div>
  );
};

export default tandc;
