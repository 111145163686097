import React, { useState, useEffect, useCallback, useRef } from "react";
import axios from "axios";
import { ReactComponent as PlotMapSVG } from "../assets/PlotMap.svg";
import "../styles/tailwind.css";

const PlotMap = ({ onPlotsSelected }) => {
  const [plots, setPlots] = useState([]);
  const [selectedPlot, setSelectedPlot] = useState(null); // Change to single plot
  const svgRef = useRef(null);

  const getPlotColor = (status) => {
    switch (status) {
      case "booked":
        return "darkkhaki";
      case "available":
        return "gray";
      case "selected":
        return "green";
      default:
        return "gray";
    }
  };

  const updatePlotColors = useCallback((plots) => {
    if (svgRef.current) {
      plots.forEach((plot) => {
        const plotElement = svgRef.current.getElementById(plot._id);
        if (plotElement) {
          plotElement.style.fill = getPlotColor(plot.status);
        }
      });
    }
  }, []);

  useEffect(() => {
    const fetchPlots = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/plots`
        );
        setPlots(response.data);
        updatePlotColors(response.data);
      } catch (error) {
        console.error("Error fetching plots:", error);
      }
    };
    fetchPlots();
  }, [updatePlotColors]);

  const handlePlotClick = (plotId) => {
    const plot = plots.find((p) => p._id === plotId);

    if (plot) {
      if (plot.status === "booked") {
        return; // Do nothing if the plot is booked
      }
      
      // If there's already a selected plot, clear its color
      if (selectedPlot) {
        updateSelectedPlotColor(selectedPlot, false); // Clear previous selection
      }

      // Update the state to the new selected plot
      const newSelectedPlot = selectedPlot === plotId ? null : plotId;
      setSelectedPlot(newSelectedPlot);
      onPlotsSelected(newSelectedPlot ? [newSelectedPlot] : []); // Pass selected plot to parent

      // Update the color of the newly selected plot
      updateSelectedPlotColor(plotId, newSelectedPlot !== null);
    } else {
      console.log("plot not available");
    }
  };

  const updateSelectedPlotColor = (plotId, isSelected) => {
    if (svgRef.current) {
      const plotElement = svgRef.current.getElementById(plotId);
      if (plotElement) {
        plotElement.style.fill = isSelected
          ? getPlotColor("selected")
          : getPlotColor("available");
      }
    }
  };

  return (
    <div className="relative w-full h-full overflow-hidden p-2 md:p-8 border-2 rounded-xl shadow bg-white">
      <div className="plot-map w-full h-full flex items-center justify-center">
        <PlotMapSVG
          ref={svgRef}
          className="w-full h-auto max-w-full max-h-full"
          onClick={(e) => {
            if (
              e.target.tagName === "path" ||
              e.target.tagName === "rect" ||
              e.target.tagName === "circle"
            ) {
              handlePlotClick(e.target.id);
            }
          }}
        />
      </div>
    </div>
  );
};

export default PlotMap;
