// Error404.jsx
import React from "react";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import { Link } from "react-router-dom"; // Use this if you're using React Router

const Error404 = () => {
  return (
    <div className="min-h-screen bg-gray-50 flex flex-col">
      <NavBar />
      <div className="flex-grow flex items-center justify-center p-6 sm:p-8 md:p-20">
        <div className="bg-[#CCAC00] bg-opacity-10 text-center p-6 sm:p-10 rounded-lg shadow-md max-w-xl">
          <h1 className="text-7xl sm:text-8xl md:text-9xl font-bold text-slate-500 mb-4">
            404
          </h1>
          <h2 className="text-2xl sm:text-3xl md:text-4xl font-bold text-slate-500 mb-4">
            Oops! The page you are looking for does not exist.
          </h2>
          <p className="text-base sm:text-lg text-slate-500 mb-8">
            It seems you’ve hit a broken link or the page has moved.
          </p>
          <Link
            to="/home"
            className="text-blue-600 underline text-base sm:text-lg hover:text-blue-800"
          >
            Go back to the home page
          </Link>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Error404;
