import React, { useState } from "react";
import Navbar from "../components/NavBar";
import Footer from "../components/Footer";
import emailjs from "emailjs-com";

const EnquirePage = () => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedInterest, setSelectedInterest] = useState("Interested In");

  // Handle Dropdown Toggle
  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  // Handle Interest Selection
  const handleInterestSelect = (interest) => {
    setSelectedInterest(interest);
    setShowDropdown(false);
  };

  // Function to send email using EmailJS
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm(
      'service_ucwo8k4', // Your Service ID
      'template_avz49rh', // Your Template ID
      e.target,
      'ICyNUzCnV9BJuYwiZ' // Your Public Key (acts as User ID)
    ).then((result) => {
        console.log(result.text);
        alert('Your message has been sent!');
      }, (error) => {
        console.log(error.text);
        alert('An error occurred. Please try again.');
      });
    e.target.reset(); // Reset the form after submission
  };

  return (
    <div className="flex flex-col min-h-screen">
      <Navbar />

      {/* Main Section with Animation */}
      <div className="flex flex-col lg:flex-row my-20 animate-fade-in ">

        {/* Left section with Slide-up Animation */}
        <div className="lg:w-1/2 flex-col items-center pr-10 lg:h-full animate-slide-up bg-[#CCAC00] bg-opacity-10 px-4 lg:px-20 py-4 md:py-20 rounded-r-3xl">

        <h1 className="text-[#5B5643] text-xl sm:text-2xl md:text-3xl lg:text-3xl font-semibold w-full  text-left animate-slide-up">
        Let’s Help You Find the Best Investment That Fits Your Luxury Lifestyle. <span className="text-[#CCAC00]">Reach Out Today! </span>
          </h1>

          <h1 className="text-[#5B5643] font-normal text-xs sm:text-sm md:text-lg leading-normal pt-8 pr-8 ">
          At RAK Group, we're dedicated to helping you find the home that perfectly complements your lifestyle. Whether you're seeking comfort, convenience, or luxury, reach out today and let us guide you to your ideal living space.
          </h1>
        </div>

        {/* Right section - Form with Animation */}
        <div className="lg:w-1/2 flex-col mt-10 lg:mt-0 animate-slide-in-right px-4 lg:px-20  py-4 md:py-20">
          <form onSubmit={sendEmail}>
            <div className="grid gap-6 grid-cols-1 md:grid-cols-2">
              <input
                type="text"
                name="name"
                placeholder="Your Name"
                required
                className="w-full p-2 bg-white text-black border-black border-b-2 mt-4 animate-fade-in"
              />
              <input
                type="email"
                name="email"
                placeholder="Your Email"
                required
                className="w-full p-2 bg-white text-black border-black border-b-2 mt-4 animate-fade-in delay-100"
              />
              <input
                type="tel"
                name="phone"
                placeholder="Phone Number"
                required
                className="w-full p-2 bg-white text-black border-black border-b-2 mt-6 animate-fade-in delay-200"
              />

              {/* Dropdown for Interests */}
              <div className="relative mt-6 animate-fade-in delay-300">
                <button
                  type="button"
                  className="w-full p-2 bg-white text-slate-400 border-black border-b-2 inline-flex justify-between items-center"
                  onClick={toggleDropdown}
                >
                  {selectedInterest}
                  <svg
                    className="w-4 h-4 ml-2"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M19 9l-7 7-7-7"
                    />
                  </svg>
                </button>

                {showDropdown && (
                  <div className="absolute z-10 bg-white border rounded-lg shadow-md mt-2 w-full">
                    <ul className="py-2 text-sm text-gray-700">
                      <li
                        className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                        onClick={() => handleInterestSelect("Plots")}
                      >
                       Plots
                      </li>
                      <li
                        className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                        onClick={() => handleInterestSelect("Villas")}
                      >
                        Villas
                      </li>
                      
                    </ul>
                  </div>
                )}
              </div>
            </div>

            <div className="mt-12">
              <input
                type="text"
                name="message"
                placeholder="Message (Optional)"
                required
                className="w-full p-2 bg-white text-black border-black border-b-2 animate-fade-in delay-400"
              />
              <button
                type="submit"
                className="border-2 border-[#CCAC00] hover:bg-[#CCAC00] hover:text-white text-black py-2 px-4 w-full md:w-1/2 lg:w-3/12 rounded-xl mt-12 animate-zoom-in"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default EnquirePage;
