import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Video from '../assets/RAKlogovid.mp4'; 

const SplashScreen = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate('/home');
    }, 12000); // Display splash screen for 3 seconds 

    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <div className="relative w-full h-screen bg-gray-500">
      <video 
        src={Video} 
        autoPlay 
        muted 
        className="absolute top-0 left-0 w-full h-full object-cover" 
      />
    </div>
  );
};

export default SplashScreen;

