import React from "react";
import "aframe";
import { Entity, Scene } from "aframe-react";
import { useNavigate } from "react-router-dom";
import floor from "../assets/oak.jpg";
import VRimg from "../assets/VR/4Office.jpg";

const VRExperience3 = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/vr-experience3");
  };

  const handleClickNext = () => {
    navigate("/vr-experience5");
  };
  const handleClickHome = () => {
    navigate("/plotdetailspage");
  };

  return (
    <div style={{ position: "relative", height: "100vh", width: "100vw" }}>
      {/* VR Scene */}
      <Scene embedded vr-mode-ui="enabled: true" renderer="antialias: true; maxPixelRatio: 2">

        <a-assets>
          <img
            id="groundTexture"
            src={floor}
            alt="Ground"
            crossorigin="anonymous"
          />
          <img id="skyTexture" src={VRimg} alt="Sky" crossorigin="anonymous" />
        </a-assets>
        <Entity cursor="rayOrigin: mouse" raycaster="objects: .clickable" />
        <Entity
  primitive="a-sky"
  src="#skyTexture"
  rotation="0 0 0"
  material="npot: true; minFilter: linear; magFilter: linear; shader: flat"
></Entity>


<Entity
          className="clickable"
          geometry={{ primitive: "plane", height: 0.3, width: 0.3 }} // Button size
          material={{ color: "#FFFFFF", opacity: 0.6 }} // White color with opacity
          position="-1 0 -5"
          rotation="0 0 0"
          text={{
            value: "^", // Upward arrows with text
            align: "center",
            color: "#000000",
            width: 4, // Increase text size (scaling)
            height: 4,
          }}
          events={{ click: handleClickNext }}
          animation__opacity="property: material.opacity; from: 0.6; to: 0.8; dur: 1000; dir: alternate; loop: true" // Blinking opacity
          animation__color="property: text.color; from: #000000; to: #FF0000; dur: 500; dir: alternate; loop: true" // Color animation for arrows and text
        />

<Entity
          className="clickable"
          geometry={{ primitive: "plane", height: 0.3, width: 0.3 }} // Button size
          material={{ color: "#FFFFFF", opacity: 0.6 }} // White color with opacity
          position="2 2.8 3"
          rotation="0 180 0"
          text={{
            value: "^", // Upward arrows with text
            align: "center",
            color: "#000000",
            width: 4, // Increase text size (scaling)
            height: 4,
          }}
          events={{ click: handleClick }}
          animation__opacity="property: material.opacity; from: 0.6; to: 0.8; dur: 1000; dir: alternate; loop: true" // Blinking opacity
          animation__color="property: text.color; from: #000000; to: #FF0000; dur: 500; dir: alternate; loop: true" // Color animation for arrows and text
        />

      </Scene>

      {/* Corner Button */}
      <button
        onClick={handleClickHome}
        style={{
          position: "absolute",
          top: "10px", // Adjust the position as needed
          right: "10px", // Adjust the position as needed
          zIndex: 1000, // Ensure it's above the VR scene
          padding: "10px 15px",
          backgroundColor: "##FFC300",
          color: "#000000",
          border: "none",
          borderRadius: "5px",
          cursor: "pointer",
        }}
      >
        Back
      </button>
    </div>
  );
};

export default VRExperience3;
