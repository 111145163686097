import React, { useState, useEffect } from "react";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import { Carousel } from "react-responsive-carousel";
import { Link } from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Import default carousel styles
import plots from "../assets/plots.svg";
import villas from "../assets/villas.svg";
import apartments from "../assets/apartments.svg";
import commercials from "../assets/commercials.svg";
import resorts from "../assets/resorts.svg";

import carousel1 from "../assets/aboutC1.png";
import carousel2 from "../assets/aboutC2.png";
const ProjectsPage = () => {
  // State to track the active slide and center mode
  const [activeIndex, setActiveIndex] = useState(0);
  const [isMobile, setIsMobile] = useState(false);

  const projects = [
    {
      id: 1,
      title: "Project 1",
      imageUrl: carousel1,
      
    },
    {
      id: 2,
      title: "Project 2",
      imageUrl: carousel2,
      
    },

  ];

  const handleSlideChange = (index) => {
    setActiveIndex(index);
  };

  // Check screen width to toggle center mode for mobile devices
  useEffect(() => {
    const updateMobileMode = () => {
      setIsMobile(window.innerWidth < 768); // Set mobile for screens less than 768px
    };
    window.addEventListener("resize", updateMobileMode);
    updateMobileMode(); // Set initial state based on screen width
    return () => window.removeEventListener("resize", updateMobileMode);
  }, []);

  return (
    <div className="h-screen ">
      <NavBar />

      <div className="w-full  mt:10 md:mt-20  sm:flex justify-center items-center text-left text-gray-500 h-fit">
        <div className="w-full md:p-20 p-6 bg-[#CCAC00] rounded-r-3xl bg-opacity-20 md:mr-20">
          <h1 className="font-semibold text-xl sm:text-2xl md:text-3xl  animate-slide-up text-[#5B5643]">
            Experience our Curated Properties
          </h1>
          <h1 className="mt-6 text-xs sm:text-sm md:text-lg font-light animate-fade-in delay-900 text-[#5B5643]">
            Explore our portfolio of premium real estate projects, each
            strategically located to offer unmatched convenience and modern
            luxury. With seamless access to highways, public transport, and
            essential services, navigating your daily life has never been
            easier. Whether you're drawn to the vibrant pulse of urban living or
            the calm of a suburban haven, our developments are tailored to fit
            diverse lifestyles. Designed with meticulous attention to detail,
            our communities feature a range of thoughtful amenities— from
            on-site laundry and fitness facilities to lush green spaces and
            recreational areas— ensuring a lifestyle of ease, comfort, and
            connection
          </h1>
        </div>
      </div>



      <div className="mx-4 flex flex-col justify-center items-center mt-10 sm:mt-16 animate-fade-in">
        {/* Images with Zoom-in Animation */}
        <div className="mt-10 flex flex-wrap md:grid grid-cols-2 md:px-20 justify-center gap-5 sm:gap-20 lg:gap-5 w-full sm:mb-5">
          <Link to="/plotspage">
            <div className="relative flex flex-col rounded-md justify-center items-center animate-zoom-in delay-300 ">
              <img
                src={plots}
                alt="plots"
                className="rounded-md w-full h-auto "
              />
            </div>
          </Link>

          <Link to="/villaspage">
            {" "}
            <div className="relative flex flex-col mb-6 md:mb-0 rounded-md justify-center items-center animate-zoom-in delay-400">
              <img
                src={villas}
                alt="villas"
                className="rounded-md w-full h-auto"
              />
            </div>
          </Link>
        </div>

        <div className="flex flex-wrap md:grid grid-cols-2 md:px-20 justify-center md:gap-10 sm:gap-20 lg:gap-5 w-full sm:mb-5">
          <div className="relative flex flex-col mb-6 sm:mb-0 rounded-md justify-center items-center animate-zoom-in delay-500">
            <img
              src={apartments}
              alt="apartments"
              className="rounded-md w-full h-auto"
            />
          </div>

          <div className="relative flex flex-col mb-6 sm:mb-0 rounded-md justify-center items-center animate-zoom-in delay-600">
            <img
              src={commercials}
              alt="commercials"
              className="rounded-md w-full h-auto"
            />
          </div>
        </div>

        <div className=" flex flex-wrap md:grid grid-cols-2  justify-center gap-10 sm:gap-20 lg:gap-5 w-full ">
          <div className="relative flex flex-col rounded-md justify-center items-center animate-zoom-in delay-700 md:pl-20">
            <img
              src={resorts}
              alt="resorts"
              className="rounded-md w-full h-auto"
            />
          </div>

          <div className="w-full relative flex flex-col rounded-l-2xl justify-center items-start animate-zoom-in delay-600 bg-[#5B5643] text-white px-20 py-6">
            <h1 className="text-xl sm:text-2xl md:text-3xl ">
              Want to learn more about the project?
            </h1>
            <h1 className="text:xs sm:text-sm md:text-lg mt-3 md:mt-6">
              Our experts are here to help!
            </h1>
            <h1 className="text-xl sm:text-2xl md:text-3xl mt-3 md:mt-6 flex gap-6">
              <svg
                width="34"
                height="34"
                viewBox="0 0 34 34"
                className="w-6 md:w-10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M27.4029 12.2016C27.4029 11.2416 26.6511 9.76956 25.5315 8.56956C24.5078 7.46556 23.1482 6.60156 21.8047 6.60156"
                  stroke="white"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M33.001 12.2C33.001 6.008 27.9947 1 21.8047 1"
                  stroke="white"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M32.9996 27.128C32.9996 27.704 32.8714 28.296 32.599 28.872C32.3266 29.448 31.9741 29.992 31.5094 30.504C30.7242 31.368 29.8589 31.992 28.8815 32.392C27.92 32.792 26.8785 33 25.7568 33C24.1224 33 22.3758 32.616 20.533 31.832C18.6903 31.048 16.8476 29.992 15.0208 28.664C13.1781 27.32 11.4315 25.832 9.76503 24.184C8.11458 22.52 6.62436 20.776 5.29438 18.952C3.98043 17.128 2.92286 15.304 2.15372 13.496C1.38457 11.672 1 9.928 1 8.264C1 7.176 1.19229 6.136 1.57686 5.176C1.96143 4.2 2.57033 3.304 3.4196 2.504C4.44512 1.496 5.56679 1 6.75255 1C7.20122 1 7.64989 1.096 8.05048 1.288C8.4671 1.48 8.83565 1.768 9.12408 2.184L12.8416 7.416C13.13 7.816 13.3383 8.184 13.4826 8.536C13.6268 8.872 13.7069 9.208 13.7069 9.512C13.7069 9.896 13.5947 10.28 13.3704 10.648C13.1621 11.016 12.8576 11.4 12.4731 11.784L11.2552 13.048C11.079 13.224 10.9989 13.432 10.9989 13.688C10.9989 13.816 11.0149 13.928 11.0469 14.056C11.095 14.184 11.1431 14.28 11.1751 14.376C11.4636 14.904 11.9603 15.592 12.6653 16.424C13.3864 17.256 14.1556 18.104 14.9888 18.952C15.8541 19.8 16.6873 20.584 17.5366 21.304C18.3698 22.008 19.0589 22.488 19.6037 22.776C19.6838 22.808 19.7799 22.856 19.8921 22.904C20.0203 22.952 20.1485 22.968 20.2927 22.968C20.5651 22.968 20.7734 22.872 20.9497 22.696L22.1675 21.496C22.5681 21.096 22.9526 20.792 23.3212 20.6C23.6897 20.376 24.0583 20.264 24.4589 20.264C24.7633 20.264 25.0838 20.328 25.4363 20.472C25.7889 20.616 26.1574 20.824 26.558 21.096L31.8619 24.856C32.2785 25.144 32.5669 25.48 32.7432 25.88C32.9034 26.28 32.9996 26.68 32.9996 27.128Z"
                  stroke="white"
                  stroke-miterlimit="10"
                />
              </svg>
              9059005900
            </h1>
          </div>
        </div>
      </div>

      <div className="mx-4 sm:mx-12 md:mx-20 lg:mx-20 flex flex-col justify-center items-center mt-10 sm:mt-16 animate-fade-in">
        <h1 className="font-semibold text-xl sm:text-2xl md:text-3xl animate-slide-up text-left w-full text-[#5B5643]">
          Other Projects
        </h1>

        <div className="w-full flex flex-col justify-center items-center mt-6">
          <div className="w-full relative justify-center flex flex-col">
            <hr className="bg-gray-400 h-0.5 w-full absolute" />
            <hr className="bg-yellow-500 h-1 w-1/4 absolute" />
          </div>
        </div>
      </div>

      {/* Image Carousel with Animations */}
      <div className="w-full mt-10 px-4  animate-fade-in delay-300 ">
        <Carousel
          showThumbs={false}
          infiniteLoop={true}
          autoPlay={true}
          showStatus={false}
          centerMode={!isMobile} // Disable centerMode on mobile
          centerSlidePercentage={60} // Show 60% width for the center image
          dynamicHeight={false}
          onChange={handleSlideChange}
        >
          {projects.map((project, index) => (
            <Link to={project.link} key={project.id}>
              <div
                className={`carousel-item ${
                  index === activeIndex ? "active-slide" : ""
                } animate-zoom-in delay-${index * 100}`}
              >
                <img
                  src={project.imageUrl}
                  alt={project.title}
                  className="carousel-image"
                />
              </div>
            </Link>
          ))}
        </Carousel>
      </div>

      <Footer />
    </div>
  );
};

export default ProjectsPage;
