import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import NavBarHome from "../components/NavBarHome";
import Footer from "../components/Footer";
import { useAuth } from "../context/AuthContext";
import ToastMsg from "../components/ToastMsg";
import hero from "../assets/hero-image.png";

const LoginPage = () => {
  const [formData, setFormData] = useState({ email: "", password: "" });
  const [error, setError] = useState("");
  const [showToast, setShowToast] = useState(false);
  const { login } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  

  const { email, password } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const getRedirectPath = (role) => {
    if (location.state?.from) return location.state?.from;
    if (role === 'user') return '/user-dashboard';
    if (role === 'agent') return '/agent-dashboard';
    if (role === 'owner') return '/owner-dashboard';
  };


  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      const role = await login(email, password);  // Wait for role to be returned
      const redirectPath = getRedirectPath(role); // Pass the role to getRedirectPath
      navigate(redirectPath);
    } catch (err) {
      setShowToast(true);
      setError("Login failed. Please check your credentials and try again.");
      console.error(err);
    }
  };

  const handleCloseToast = () => {
    setShowToast(false);
  };



  return (
    <div className=" h-screen md:h-full">
    <div
      className=" bg-cover bg-center h-full w-full animate-fade-in"
      style={{ backgroundImage: `url(${hero})` }}
    >
      <NavBarHome />
      <div className="h-full flex flex-col lg:flex-row animate-fade-in-up md:px-40 text-[#5B5643]">
   
        <div className="h-full w-full flex justify-start items-center px-4 sm:px-10 lg:px-0 animate-fade-in-up ">
          <div className="bg-white w-full my-40 max-w-lg md:max-w-3/5 lg:w-3/5 rounded-3xl flex flex-col items-center p-4 sm:p-6 md:p-8 animate-fade-in flex-grow">
            <h1 className=" font-bold text-xl sm:text-2xl md:text-3xl sm:mt-4 md:mt-8 w-full text-center animate-fade-in-up">
              Login to RAK Groups
            </h1>
            <h1 className=" font-normal text-xs sm:text-sm md:text-lg mt-2 sm:mt-4 md:mt-6 text-center px-2 md:px-6 animate-fade-in-up">
              Seamless Access, Elevating Experience. Unlock Your Property
              Journey with RAK Groups
            </h1>
            {error && (
              <p className="text-red-500 text-center animate-fade-in-up">
                {error}
              </p>
            )}
            <div className="w-full animate-fade-in">
              <form
                onSubmit={onSubmit}
                className="w-full flex flex-col sm:mt-6 animate-fade-in-up"
              >
                <input
                  type="email"
                  name="email"
                  value={email}
                  onChange={onChange}
                  placeholder="Email or Username"
                  required
                  className="w-full p-2 border rounded-xl   border-zinc-500 autofill:bg-transparent mt-4 focus:outline-none focus:ring focus:ring-gray-400 transition duration-300 animate-fade-in-up"
                />
                <input
                  type="password"
                  name="password"
                  value={password}
                  onChange={onChange}
                  placeholder="Password"
                  required
                  className="w-full p-2 border rounded-xl   border-zinc-500 autofill:bg-transparent mt-4 focus:outline-none focus:ring focus:ring-gray-400 transition duration-300 animate-fade-in-up"
                />
                <div className="flex items-center mt-4 animate-fade-in">
                  <input
                    id="remember"
                    type="checkbox"
                    value=""
                    className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:[#CCAC00]"
                  />
                  <label
                    htmlFor="remember"
                    className="ml-2 text-sm font-medium text-gray-300"
                  >
                    Remember me
                  </label>
                </div>
                <button
                  type="submit"
                  className="bg-[#CCAC00] hover:bg-[#CCAC00] hover:text-white py-2 px-4 rounded-lg h-10 md:h-12 w-full mt-4 sm:mt-6 transition duration-300 ease-in-out transform hover:scale-105"
                >
                  Login
                </button>
              </form>
            </div>
            <div className="flex justify-center text-xs sm:text-sm md:text-lg items-center w-full mt-4 md:mt-8 animate-fade-in-up">
              <span className="">Don't have an account? &nbsp;</span>
              <Link to="/register">
                <span className="text-[#CCAC00] hover:underline transition duration-300 ease-in-out animate-fade-in-up">
                  Register Now
                </span>
              </Link>
            </div>
          </div>
        </div>
      </div>

    </div>
    <Footer />
      {showToast && (
        <ToastMsg
          notificationMsg="Login failed. Please check your credentials and try again."
          duration={2000} // duration in milliseconds
          onClose={handleCloseToast}
          className="animate-fade-in-up"
        />
      )}
    </div>

  );
};

export default LoginPage;
