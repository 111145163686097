import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import PlotMap from "../components/PlotMap";
import BookingModal from "../components/BookingModal";
import { getAuthToken } from "../utils/auth";

const BookingPage = () => {
  const [selectedPlots, setSelectedPlots] = useState([]);
  const [plotDetails, setPlotDetails] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPlotDetails = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/plots/details`,
          { plotIds: selectedPlots }
        );

        setPlotDetails(response.data);
      } catch (error) {
        console.error("Error fetching plot details:", error);
      }
    };

    if (selectedPlots.length > 0) {
      fetchPlotDetails();
    }
  }, [selectedPlots]);

  const handlePlotsSelected = (plots) => {
    setSelectedPlots(plots);
  };

  const handleBooking = async () => {
    if (!selectedPlots.length) {
      alert("Please select plots to book");
      return;
    }
    console.log(selectedPlots);
    const token = getAuthToken();
    if (!token) {
      // Redirect to login if not logged in
      navigate("/login");
      return;
    }
    // Open the modal to confirm booking
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleModalConfirm = async (agentId) => {
    try {
      const token = getAuthToken();
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      await axios.post(`${process.env.REACT_APP_API_URL}/api/plots/book`, {
        plotIds: selectedPlots,
        agentReferenceId: agentId,
      });
      setIsModalOpen(false);
      // Optionally, redirect or show a success message
    } catch (error) {
      console.error("Error booking plots: ", error);
    }
  };

  return (
    <div className="flex flex-col w-full animate-fade-in text-[#5B5643] ">
      <div className="p-6 md:py-0 md:px-20 md:mt-20 mb-6">
        <h1 className="md:text-2xl font-semibold">
          Select the perfect plot for your dream home today.
        </h1>
        <h1 className="text-xs md:text-base">
          Secure your ideal space and start the journey to owning your dream
          home by booking now.
        </h1>
      </div>

      <div className=" w-full  px-4 md:px-8 lg:px-20 flex flex-col md:flex-row md:justify-center items-center animate-slide-up md:space-x-6 mb-6 ">
        <div className="flex flex-col w-full md:w-3/4 animate-zoom-in ">
          <PlotMap onPlotsSelected={handlePlotsSelected} />
          <div className="flex flex-wrap mt-5 space-x-4 md:space-x-6">
            <div className="flex items-center mb-2">
              <div className="h-4 w-4 sm:h-8 sm:w-8 bg-yellow-500 mr-2 rounded-md"></div>
              <h1 className="text-xs sm:text-sm md:text-base">
                Reserved Plots
              </h1>
            </div>
            <div className="flex items-center mb-2">
              <div className="h-4 w-4 sm:h-8 sm:w-8 bg-gray-500 mr-2 rounded-md"></div>
              <h1 className="text-xs sm:text-sm md:text-base">
                Available Plots
              </h1>
            </div>
            <div className="flex items-center mb-2">
              <div className="h-4 w-4 sm:h-8 sm:w-8 bg-green-700 mr-2 rounded-md"></div>
              <h1 className="text-xs sm:text-sm md:text-base">
                Selected Plots
              </h1>
            </div>
            <div className="flex items-center mb-2">
              <div className="h-4 w-4 sm:h-8 sm:w-8 bg-green-300 mr-2 rounded-md"></div>
              <h1 className="text-xs sm:text-sm md:text-base">
                Park Area
              </h1>
            </div>
            <div className="flex items-center mb-2">
              <div className="h-4 w-4 sm:h-8 sm:w-8 bg-white mr-2 rounded-md"></div>
              <h1 className="text-xs sm:text-sm md:text-base">
                *Plot Number 5 is Club House
              </h1>
            </div>
          </div>
        </div>

        <div className="w-full md:h-full md:w-1/4 mt-6 md:mt-0 animate-slide-in-right p-6 border-2 rounded-xl shadow ">
          <div className="flex flex-col mb-2">
            {plotDetails.length > 0 ? (
              plotDetails.map((plot) => (
                <div key={plot._id} className="">
                  <div className="flex flex-col  mb-2">
                    <h1 className="text-xs font-semibold ">Plot Number </h1>
                    <h1 className="text-lg border-b-2 h-6 md:text-xl font-semibold">
                      {plot.name}
                    </h1>
                  </div>
                  <div className="flex flex-col  mb-2">
                    <h1 className="text-xs font-semibold  ">Plot Dimension</h1>
                    <h1 className="text-lg border-b-2 h-6 md:text-xl font-semibold">
                      {plot.location}
                    </h1>
                  </div>
                  <div className="flex flex-col  mb-2">
                    <h1 className="text-xs font-semibold  ">Plot Status</h1>
                    <h1 className="text-lg border-b-2 h-6 md:text-xl font-semibold">
                      {plot.status}
                    </h1>
                  </div>
                </div>
              ))
            ) : (
              <p>Login to see the plot details.</p>
            )}
          </div>

          <button
            onClick={handleBooking}
            className="w-full h-10 md:h-12 px-6 rounded-2xl mt-4 border-2 border-yellow-500 font-semibold"
          >
            Continue Booking
          </button>
        </div>
      </div>
      <BookingModal
        isOpen={isModalOpen}
        selectedPlots={selectedPlots}
        onClose={handleModalClose}
        onConfirm={handleModalConfirm}
      />
    </div>
  );
};

export default BookingPage;
