import React from "react";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";

const AboutPage = () => {
  return (
    <div className="h-screen ">
      <NavBar />

      <div className="w-full mt-10 sm:mt-32  sm:flex justify-center items-center text-left text-gray-500 h-fit">
        <div className="md:w-2/5 md:p-20 p-6">
          <h1 className="font-semibold text-xl sm:text-2xl md:text-3xl  animate-slide-up">
            Why RAK Groups?
          </h1>
          <h1 className="mt-6 text-xs sm:text-sm md:text-lg animate-fade-in delay-200">
            As one of India’s premier developers of plotted and gated
            communities, we provide an idyllic sanctuary away from the frenetic
            pace of city life. Awaken to the gentle melodies of nature and
            immerse yourself in a harmonious, healthy lifestyle within our
            meticulously crafted living spaces.
          </h1>
        </div>
        <div className="md:w-3/5 md:p-20 p-6 bg-[#CCAC00] rounded-l-3xl bg-opacity-40">
          <h1 className="font-semibold text-xl sm:text-2xl md:text-3xl animate-slide-up text-[#5B5643]">
          Who are we?
          </h1>
          <h1 className="mt-6 text-xs sm:text-sm md:text-lg  animate-fade-in delay-900 text-[#5B5643]">
          We began as an land acquisition company in 2006 at north Bangalore, where we fulfilled many big company needs for the major projects. As time passed we emerged as best real-estate conglomerate in north Bangalore, which lead to the birth of the name “RAK groups” in 2020. The new journey began ,we crafted ourselves as the brand to fulfill the needs for our costumers, where we took the step ahead in the field of residential plots ,villas and apartment integrating the redefined heritage community . 
          </h1>
        </div>
      </div>

      <div className="md:px-20  w-full sm:my-32 h-fit">
      <div className="mt-12 flex flex-wrap justify-center gap-6 sm:gap-10 lg:gap-10 w-full mb-14 text-center border shadow rounded-2xl py-14">
          <div className="flex flex-col space-y-2 justify-center items-center w-2/5 sm:w-1/4 animate-zoom-in delay-1000">
            <h1 className="font-bold text-xl sm:text-2xl md:text-3xl text-[#CCAC00]">
             10,01,880+ <span className="text-sm sm:text-lg md:text-xl text-gray-500 font-semibold">
              Sqft
            </span>
            </h1>
            
            <h1 className="text-xs sm:text-sm md:text-lg text-gray-500 ">
            Over 10,01,880 sq/ft luxury plots for “Home Away From Home” experience
            </h1>
          </div>

          <div className="flex flex-col space-y-2 justify-center items-center w-2/5 sm:w-1/4 animate-zoom-in delay-1200">
            <h1 className="font-bold text-xl sm:text-2xl md:text-3xl text-[#CCAC00]">
            350+ <span className="text-sm sm:text-lg md:text-xl text-gray-500 font-semibold">
            Family
            </span>
            </h1>
            
            <h1 className="text-xs sm:text-sm md:text-lg text-gray-500 ">
            Laying the foundation of happiness
            </h1>
          </div>

          <div className="flex flex-col space-y-2 justify-center items-center w-2/5 sm:w-1/4 animate-zoom-in delay-1200">
            <h1 className="font-bold text-xl sm:text-2xl md:text-3xl text-[#CCAC00]">
              24/7 <span className="text-sm sm:text-lg md:text-xl text-gray-500 font-semibold">
              Help
            </span>
            </h1>
            
            <h1 className="text-xs sm:text-sm md:text-lg text-gray-500 ">
              24/7 Help service for all customers to guide and support
            </h1>
          </div>
        </div>

      </div>

      <div className="w-full bg-[#5B5643] p-6 md:pt-10 md:px-20 mb-4 md:mb-14">
        <h1 className="text-white font-semibold text-xl sm:text-2xl md:text-3xl mb-2">
          Key Features
        </h1>
        <div className="w-full relative justify-center flex flex-col md:mb-10 mb-4">
          <hr className="bg-gray-400 h-0.5 w-full absolute" />
          <hr className="bg-[#CCAC00] h-1 w-1/4 absolute" />
        </div>
        <div className="grid md:grid-cols-5 text-white items-center md:justify-center space-y-4 md:space-y-0 text-xs sm:text-sm md:text-lg">
          <div className="flex space-x-6 md:p-6 items-center md:justify-start justify-between">
            
            <h1>Heritage Design</h1>
          </div>
          <div className="flex space-x-6 md:p-6 items-center md:justify-start justify-between">
            
            <h1>Luxury Community</h1>
          </div>
          <div className="flex space-x-6 md:p-6 items-center md:justify-start justify-between">
           
            <h1>Smart App</h1>
          </div>
          <div className="flex space-x-6 md:p-6 items-center md:justify-start justify-between">
            
            <h1>High Returns</h1>
          </div>
          <div className="flex space-x-6 md:p-6 items-center md:justify-start justify-between">
            
            <h1>Uniqueness</h1>
          </div>
          <div className="flex space-x-6 md:p-6 items-center md:justify-start justify-between">
          
            <h1>24/7 Helpline</h1>
          </div>
          <div className="flex space-x-6 md:p-6 items-center md:justify-start justify-between">
            
            <h1>High Quality</h1>
          </div>
          <div className="flex space-x-6 md:p-6 items-center md:justify-start justify-between">
          
            <h1>Amenitis</h1>
          </div>
          <div className="flex space-x-6 md:p-6 items-center md:justify-start justify-between">
           
            <h1>Gated Community</h1>
          </div>
          <div className="flex space-x-6 md:p-6 items-center md:justify-start justify-between">
           
            <h1>One time Delivery</h1>
          </div>
          <div className="flex space-x-6 md:p-6 items-center md:justify-start justify-between">
           
           <h1>Lifetime Club Membership</h1>
         </div>
         <div className="flex space-x-6 md:p-6 items-center md:justify-start justify-between">
           
           <h1>Resale at One Click</h1>
         </div>
         <div className="flex space-x-6 md:p-6 items-center md:justify-start justify-between">
           
           <h1>Prime Location</h1>
         </div>
         <div className="flex space-x-6 md:p-6 items-center md:justify-start justify-between">
           
           <h1>Transparency</h1>
         </div>
         <div className="flex space-x-6 md:p-6 items-center md:justify-start justify-between">
           
           <h1>Family Member of RAK Foundation</h1>
         </div>
        </div>
      </div>


      <div className="w-full h-fit sm:flex justify-center items-center text-left text-gray-500 mb-6 sm:mb-32">
        <div className="md:w-2/5 md:p-20 p-6 bg-[#5B5643] rounded-r-3xl">
        <h1 className="font-semibold text-xl sm:text-2xl md:text-3xl  animate-slide-u text-white">
              Our Vision
            </h1>
            <h1 className="mt-6 text-xs sm:text-sm md:text-lg  animate-fade-in delay-900 text-white">
              To ensure the longevity of our company through repeat and referral
              business achieved by customer satisfaction in all areas including
              timeliness, service-minded attention attitudes to detail dedicated
              and to provide quality construction. Will treat all employees
              fairly and involve them in the quality improvement process to
              ensure responsiveness and cost-effective work execution.
            </h1>
        </div>
        <div className="md:w-3/5 md:p-20 p-6 ">
        <h1 className="font-semibold text-xl sm:text-2xl md:text-3xl  animate-slide-u">
              Our Misson
            </h1>
            <h1 className="mt-6 text-xs sm:text-sm md:text-lg  animate-fade-in delay-900">
              To be the most excellent construction and real estate development
              company committed to total customer satisfaction on our strengths
              - by building innovative designs, superlative quality of material,
              cutting-edge technology demonstrating workmanship timely the
              completion highest standards & of workmanship.
            </h1>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AboutPage;
