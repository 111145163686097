import React from "react";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import PlotsComponent from "../components/PlotsComponent";
import PlotsComponent1 from "../components/PlotsComponent1";

const PlotsPage = () => {
  return (
    <div className="flex flex-col min-h-screen animate-fade-in">
      <NavBar />

      <div className="w-full mt:10 md:mt-20  sm:flex justify-center items-center text-left text-gray-500 h-fit">
        <div className="w-full md:p-20 p-6 bg-[#CCAC00] rounded-r-3xl bg-opacity-20 md:mr-20">
          <h1 className="font-semibold text-xl sm:text-2xl md:text-3xl  animate-slide-up text-[#5B5643]">
          Exclusive Luxury Plots for Sale in Bengaluru
          </h1>
          <h1 className="mt-6 text-xs sm:text-sm md:text-lg  animate-fade-in delay-900 text-[#5B5643]">
          Dream Home is a premier gated community perfectly positioned in a prime downtown location. Enjoy the convenience of being just steps away from beautiful parks, as well as the finest shopping, dining, and entertainment venues. With effortless access to freeways, public transit, and trolleys, commuting is a breeze. On-site amenities, including laundry facilities, ensure your comfort and convenience, making Dream Home the ultimate blend of urban living and modern convenience.
          </h1>
        </div>
      </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-10 lg:gap-20 justify-between w-full p-6 md:p-20">
        <PlotsComponent1 className="animate-zoom-in" />
          <PlotsComponent className="animate-zoom-in" />
        </div>
      
      <Footer />
    </div>
  );
};

export default PlotsPage;
