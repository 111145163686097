import React, { useState, useEffect } from "react";
import axios from "axios";
import { useAuth } from "../context/AuthContext";
import qrCode from "../assets/Qrcode.png";
import { CopyToClipboard } from "react-copy-to-clipboard";
import copy from "../assets/copy.svg"
const BookingModal = ({ isOpen, onClose, selectedPlots }) => {
  const [agentId, setAgentId] = useState("");
  const [plotDetails, setPlotDetails] = useState([]);
  const { user } = useAuth();

  useEffect(() => {
    const fetchPlotDetails = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/plots/details`,
          { plotIds: selectedPlots }
        );
        setPlotDetails(response.data);
      } catch (error) {
        console.error("Error fetching plot details:", error);
      }
    };

    if (isOpen && selectedPlots.length > 0) {
      fetchPlotDetails();
    }
  }, [isOpen, selectedPlots]);

  if (!isOpen) return null;

  const bookingChargePerPlot = 50000;
  const totalAmount = plotDetails.length * bookingChargePerPlot;

  const handlePayment = async () => {
    try {
      const orderResponse = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/payment/createOrder`,
        { amount: totalAmount * 100 } // amount in paise
      );

      const { id: order_id, currency, amount } = orderResponse.data;

      const options = {
        key: process.env.REACT_APP_RAZORPAY_KEY_ID, // Enter the Key ID generated from the Dashboard
        amount: amount.toString(),
        currency: currency,
        name: "Your Company Name",
        description: "Test Transaction",
        order_id: order_id,
        handler: async function (response) {
          const paymentData = {
            razorpay_order_id: response.razorpay_order_id,
            razorpay_payment_id: response.razorpay_payment_id,
            razorpay_signature: response.razorpay_signature,
            plotIds: selectedPlots,
            agentId: agentId,
            userId: user.id,
          };

          const verifyPaymentResponse = await axios.post(
            `${process.env.REACT_APP_API_URL}/api/payment/verifyPayment`,
            paymentData
          );

          if (verifyPaymentResponse.status === 200) {
            alert("Successful");
          } else {
            alert("Payment verification failed");
          }
        },
        prefill: {
          name: user?.fname,
          email: user?.email,
          contact: user?.mnumber,
        },
        notes: {
          address: "Some Address",
        },
        theme: {
          color: "#3399cc",
        },
      };

      const rzp1 = new window.Razorpay(options);
      rzp1.open();
    } catch (error) {
      console.error("Error during payment process:", error);
      alert("Error during payment process. Please try again.");
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75">
      <div className="w-full sm:max-w-lg md:max-w-2xl lg:max-w-3xl mx-auto p-4 sm:p-4 md:p-6 bg-white shadow-md rounded">
        <h2 className="text-lg sm:text-xl md:text-2xl font-semibold mb-4">Booking Details</h2>

        <div className="w-full flex flex-col md:flex-row justify-center items-center space-x-4">

        <div className="mb-4 p-4">
          {plotDetails.map((plot, index) => (
            <div key={plot._id} className="flex flex-col sm:flex-row justify-between mb-4">
              <div>
                <h3 className="font-semibold">{plot.name}</h3>
                <p>Property: {plot.name}</p>
                <p>Plot size: {plot.location} sq.ft</p>
                <p>Advance Booking Amt.: Rs. </p>
                <p>{bookingChargePerPlot.toLocaleString("en-IN")}</p>
              </div>
            </div>
          ))}
          <div className="mt-4 ">
            <p className="font-semibold">
              Total Amount: Rs. {totalAmount.toLocaleString("en-IN")}
            </p>
          </div>
        </div>

        <div className="grid grid-cols-1 gap-4 mb-4 border-l-2  shadow p-4">
          <input
            type="text"
            placeholder="Full Name*"
            value={user?.fname} 
            readOnly={!!user?.fname}
            className="p-2 border rounded bg-gray-100"
          />
          <input
            type="text"
            placeholder="Mobile Number*"
            value={user?.mnumber}
            readOnly={!!user?.mnumber}
            className="p-2 border rounded bg-gray-100"
          />
          <input
            type="text"
            placeholder="Email Address*"
            value={user?.email}
            readOnly={!!user?.femailname}
            className="p-2 border rounded bg-gray-100"
          />
          <input
            type="text"
            placeholder="Agent reference ID"
            value={agentId}
            onChange={(e) => setAgentId(e.target.value)}
            className="mt-1 block w-full p-2 border rounded-md"
          />
        </div>
        <div className="grid grid-cols-1 gap-4 mb-4 border-l-2  shadow p-4">
        <img alt="QR" width={200} src={qrCode}></img>
        <CopyToClipboard
         text={"rakgroups@ybl"}
         onCopy={() => {
           console.log("Copied");
          }}><span style={{ display: "inline-flex", alignItems: "center", gap: "0.5rem" }}>rakgroups@ybl<img src={copy} alt="copy_add" /></span>
          </CopyToClipboard>
        </div>
        </div>





        <div className="flex flex-col sm:flex-row justify-end">
          <button
            onClick={onClose}
            className="bg-gray-500 text-white py-2 px-4 rounded mb-2 sm:mb-0 sm:mr-2"
          >
            Cancel
          </button>
          <button
            onClick={handlePayment} 
            className="bg-[#CCAC00] text-white py-2 px-4 rounded"
          >
            Confirm Booking
          </button>
        </div>
      </div>
    </div>
  );
};

export default BookingModal;
