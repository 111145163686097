import React from "react";
import prope from "../assets/plot_component2.png";
import { Link } from "react-router-dom";
import brochure from "../assets/pdf/AffluentVillas.pdf.pdf";

const PlotsComponent = () => {
  return (
    <div className="bg-white rounded-md border shadow-md text-[#5B5643]">
      <img
        src={prope}
        alt="properties"
        className="bg-center bg-cover w-full rounded-md"
      />
      <div className="flex justify-between items-center mt-2 mx-2 md:px-5">
        <h1 className="font-semibold text-sm sm:text:lg md:text-xl ">RAK Affluent</h1>
        <div>
          <h1 className="font-bold sm:text-2xl text-right text-[#CCAC00]">50,000Rs</h1>
          <h1 className=" sm:text-xs underline">Advance Booking</h1>
        </div>
      </div>

      <div className="flex justify-between items-center mt-1 mx-2 md:px-5 text-sm sm:text:lg md:text-xl">
        <h1 className="font-semibold sm:text-lg flex">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="size-6 text-[#CCAC00] "
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
              fill="white"
              className="fill-white"
            />
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z"
            />
          </svg>
          Devanhalli, Bengaluru, 562110
        </h1>
      </div>

      <div className="flex justify-between items-center mt-3 mx-2 md:px-5">
        <h1 className="font-semibold text-sm sm:text:lg md:text-xl text-[#5B5643]">
          Plots
        </h1>
      </div>

      <div className="grid grid-cols-3 gap-4 my-3 mx-2 md:px-5">
        <div className="flex flex-col px-2">
          <h1 className=" text-sm sm:text-base font-semibold ">Unit size:</h1>
          <h1 className=" text-xs sm:text-base sm:font-normal ">
          1020 to 2400 Sqft
          </h1>
        </div>
        <div className="flex flex-col border-l border-black px-2">
          <h1 className=" text-sm sm:text-base font-semibold ">Price:</h1>
          <h1 className=" text-xs sm:text-base sm:font-normal ">
          1.39 Cr Onwards*
          </h1>
        </div>
        <div className="flex flex-col border-l border-black px-2">
          <h1 className=" text-sm sm:text-base font-semibold ">Status:</h1>
          <h1 className=" text-xs sm:text-base sm:font-normal ">Registration Open*</h1>
        </div>
      </div>

      <div className="flex justify-between items-center space-x-2 sm:space-x-6 my-5 mx-2 md:px-5">
        <button className="bg-white border-2 border-[#CCAC00] text-sm sm:text-xl font-semibold rounded-xl w-1/2 h-10 sm:h-12 hover:bg-[#CCAC00] hover:text-white">
          <Link to="/plotdetailspage">View Project</Link>
        </button>
        <button className="bg-white border-2 border-[#CCAC00] text-sm sm:text-xl font-semibold rounded-xl w-1/2 h-10 sm:h-12 hover:bg-[#CCAC00] hover:text-white">
          <a href={brochure} download="Brochure.pdf">
            Download Brouchure
          </a>
        </button>
      </div>
    </div>
  );
};

export default PlotsComponent;
